export const homeObjOne = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'About Fabio Rocha',
  headline: 'Software engineer',
  description:
    'I am a senior software engineer with over 15 years of experience in developing web platforms and mobile applications. I developed systems for large companies, corporate applications that helped companies improve and automate their processes.',
  buttonLabel: 'Get Started',
  imgStart: '',
  img: require('../../images/FABIO.JPG'),
  alt: 'Fabio Rocha',
  start: ''
};
